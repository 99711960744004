<template>
  <div :class="{ loading: loading }">
    <grid-layout
      :layout.sync="filtredLayout"
      :col-num="14"
      :row-height="13"
      :is-draggable="draggable"
      :is-resizable="resizable"
      :responsive="false"
      :vertical-compact="false"
      :prevent-collision="true"
      :use-css-transforms="false"
      :margin="[2, 1]"
    >
      <grid-item
        v-for="(item, index) in filtredLayout"
        :static="item.s"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="index"
        @move="moveEvent"
        @moved="movedEvent"
        @resize="resizeEvent"
        @resized="resizedEvent"
        drag-allow-from=".vue-draggable-handle"
        drag-ignore-from=".no-drag"
        :class="{
          header: item.header,
          filled: item.filled,
          notFilled: item.notFilled,
        }"
      >
        <span class="text" v-if="item.header">{{ item.text }}</span>
        <div class="tooltiptext" v-if="item.filled">
          {{ item.record.time }} - {{ item.record.ends }}
          <p v-if="item.filled">
            <center>
              {{
                item.record.name ? item.record.name : item.record.subject.name
              }}
            </center>
            <center>
              {{ item.record.classeRoom.name }}
            </center>
            <center>{{ item.record.room ? item.record.room.name : "" }}</center>
          </p>
        </div>
        <div class="tooltiptext" v-else-if="!item.filled && !item.header">
          {{ getTime(item.y) }}
        </div>
        <div
          v-if="item.filled"
          class="text"
          @click="clickSchedule(item.record, item.record.day, item.record.time)"
        >
          <div @click.stop class="vue-draggable-handle">
            <a-icon type="drag" />
          </div>
          <div class="no-drag">
            <center>
              {{
                item.record.name ? item.record.name : item.record.subject.name
              }}
            </center>
            <center v-if="item.h > 3">
              {{ item.record.classeRoom.name }}
            </center>
            <center v-if="item.h > 4">
              {{ item.record.room ? item.record.room.name : "" }}
            </center>
          </div>
        </div>

        <div
          v-if="item.notFilled"
          @click="clickSchedule(null, item.x / 2 - 1, getTime(item.y))"
        >
          -
        </div>
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout";
export default {
  components: {
    GridLayout,
    GridItem,
  },
  props: ["dataGrid", "loading", "errorDrag", "updating"],
  created() {
    this.initiateLayout();
  },
  data() {
    return {
      replacing: false,
      eventLog: [],
      index: 0,
      layout: [],
      skull: [
        //TopHeader
        {
          x: 0,
          y: 0,
          w: 2,
          h: 2,
          i: "0",
          text: this.$t("emploi.temps"),
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 2,
          y: 0,
          w: 2,
          h: 2,
          i: "1",
          text: this.$t("days.monday"),
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 4,
          y: 0,
          w: 2,
          h: 2,
          i: "2",
          text: this.$t("days.tuesday"),
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 6,
          y: 0,
          w: 2,
          h: 2,
          i: "3",
          text: this.$t("days.wednesday"),
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 8,
          y: 0,
          w: 2,
          h: 2,
          i: "4",
          text: this.$t("days.thursday"),
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 10,
          y: 0,
          w: 2,
          h: 2,
          i: "5",
          text: this.$t("days.friday"),
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 12,
          y: 0,
          w: 2,
          h: 2,
          i: "6",
          text: this.$t("days.saturday"),
          s: true,
          visible: true,
          header: true,
        },
        //LeftHeader
        //8
        {
          x: 0,
          y: 2,
          w: 2,
          h: 4,
          i: 0,
          text: "8:00 - 9:00",
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 0,
          y: 3,
          w: 2,
          h: 1,
          i: 0,
          text: "8:15",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 4,
          w: 2,
          h: 1,
          i: 0,
          text: "8:30",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 5,
          w: 2,
          h: 1,
          i: 0,
          text: "8:45",
          s: true,
          visible: false,
          header: true,
        },

        //9
        {
          x: 0,
          y: 6,
          w: 2,
          h: 4,
          i: 0,
          text: "9:00 - 10:00",
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 0,
          y: 7,
          w: 2,
          h: 1,
          i: 0,
          text: "9:15",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 8,
          w: 2,
          h: 1,
          i: 0,
          text: "9:30",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 9,
          w: 2,
          h: 1,
          i: 0,
          text: "9:45",
          s: true,
          visible: false,
          header: true,
        },

        {
          x: 0,
          y: 10,
          w: 2,
          h: 4,
          i: 0,
          text: "10:00 - 11:00",
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 0,
          y: 11,
          w: 2,
          h: 1,
          i: 0,
          text: "10:15",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 12,
          w: 2,
          h: 1,
          i: -1,
          text: "10:30",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 13,
          w: 2,
          h: 1,
          i: -1,
          text: "10:45",
          s: true,
          visible: false,
          header: true,
        },

        {
          x: 0,
          y: 14,
          w: 2,
          h: 4,
          i: -1,
          text: "11:00 - 12:00",
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 0,
          y: 15,
          w: 2,
          h: 1,
          i: -1,
          text: "11:15",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 16,
          w: 2,
          h: 1,
          i: -1,
          text: "11:30",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 17,
          w: 2,
          h: 1,
          i: -1,
          text: "11:45",
          s: true,
          visible: false,
          header: true,
        },

        {
          x: 0,
          y: 18,
          w: 2,
          h: 4,
          i: -1,
          text: "12:00 - 13:00",
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 0,
          y: 19,
          w: 2,
          h: 1,
          i: -1,
          text: "12:15",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 20,
          w: 2,
          h: 1,
          i: -1,
          text: "12:30",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 21,
          w: 2,
          h: 1,
          i: -1,
          text: "12:45",
          s: true,
          visible: false,
          header: true,
        },

        {
          x: 0,
          y: 22,
          w: 2,
          h: 4,
          i: -1,
          text: "13:00 - 14:00",
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 0,
          y: 23,
          w: 2,
          h: 1,
          i: -1,
          text: "13:15",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 24,
          w: 2,
          h: 1,
          i: -1,
          text: "13:30",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 25,
          w: 2,
          h: 1,
          i: -1,
          text: "13:45",
          s: true,
          visible: false,
          header: true,
        },

        {
          x: 0,
          y: 26,
          w: 2,
          h: 4,
          i: -1,
          text: "14:00 - 15:00",
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 0,
          y: 27,
          w: 2,
          h: 1,
          i: -1,
          text: "14:15",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 28,
          w: 2,
          h: 1,
          i: -1,
          text: "14:30",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 29,
          w: 2,
          h: 1,
          i: -1,
          text: "14:45",
          s: true,
          visible: false,
          header: true,
        },

        {
          x: 0,
          y: 30,
          w: 2,
          h: 4,
          i: -1,
          text: "15:00 - 16:00",
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 0,
          y: 31,
          w: 2,
          h: 1,
          i: -1,
          text: "15:15",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 32,
          w: 2,
          h: 1,
          i: -1,
          text: "15:30",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 33,
          w: 2,
          h: 1,
          i: -1,
          text: "15:45",
          s: true,
          visible: false,
          header: true,
        },

        {
          x: 0,
          y: 34,
          w: 2,
          h: 4,
          i: -1,
          text: "16:00 - 17:00",
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 0,
          y: 35,
          w: 2,
          h: 1,
          i: -1,
          text: "16:15",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 36,
          w: 2,
          h: 1,
          i: -1,
          text: "16:30",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 37,
          w: 2,
          h: 1,
          i: -1,
          text: "16:45",
          s: true,
          visible: false,
          header: true,
        },

        {
          x: 0,
          y: 38,
          w: 2,
          h: 4,
          i: -1,
          text: "17:00 -18:00",
          s: true,
          visible: true,
          header: true,
        },
        {
          x: 0,
          y: 39,
          w: 2,
          h: 1,
          i: -1,
          text: "17:15",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 40,
          w: 2,
          h: 1,
          i: -1,
          text: "17:30",
          s: true,
          visible: false,
          header: true,
        },
        {
          x: 0,
          y: 41,
          w: 2,
          h: 1,
          i: -1,
          text: "17:45",
          s: true,
          visible: false,
          header: true,
        },
      ],
      draggable: true,
      resizable: true,
      index: 0,
    };
  },
  methods: {
    clickSchedule(record, day, time) {
      this.$emit("sched", record, day, time);
    },
    getTime: function (y) {
      const a = this.layout.find((el) => el.y == y && el.x == 0);
      return a ? a.text.split(" ")[0] : "";
    },
    initiateLayout() {
      this.layout = [];
      this.layout = [...this.skull];
      for (let x = 1; x <= 12; x = x + 2) {
        for (let y = 2; y <= 41; y++) {
          let grid = {
            x: x + 1,
            y: y,
            w: 2,
            h: 1,
            text: "",
            s: true,
            visible: !this.updating,
            notFilled: true,
          };
          this.layout.push(grid);
        }
      }

      this.layout.forEach((el) => {
        el.i = this.index;
        this.index = this.index + 1;
      });
    },
    moveEvent: async function (i, newX, newY) {
      return;
    },
    movedEvent: function (i, newX, newY) {
      if (newX % 2 != 0) {
        this.reload();
        return;
      }

      let duration = this.layout.find((el) => el.i == i).h;

      let time = this.layout
        .find((el) => el.y == newY && el.x == 0)
        .text.split(" ")[0];
      let day = newX / 2 - 1;

      this.$emit("sched_drag_update", i, day, time, duration);
    },
    resizeEvent: async function (i, newH, newW, newHPx, newWPx) {
      this.layout.forEach((el) => {
        if (el.notFilled) {
          el.visible = false;
        }
      });
      return;
    },
    resizedEvent: function (i, newH, newW, newHPx, newWPx) {
      if (newW > 2 || newH > 8) {
        this.reload();
        return;
      }

      let duration = newH;

      let time = this.layout.find((el) => el.i == i).record.time;
      let day = this.layout.find((el) => el.i == i).record.day;

      this.$emit("sched_drag_update", i, day, time, duration);
    },
    reload() {
      this.initiateLayout();
      // this.$emit("reload");
      this.dataGrid.forEach((schedule) => {
        let index = this.layout.findIndex(
          (el) => el.text.split(" ")[0] == schedule.time
        );

        let grid = {
          h: schedule.duration,
          i: schedule._id,
          text: `${schedule.name ? schedule.name : schedule.subject.name} ${
            schedule.room ? schedule.room.name : ""
          }  ${schedule.classeRoom.name}`,
          w: 2,
          x: 2 * schedule.day + 2,
          y: this.layout[index].y,
          s: false,
          visible: true,
          filled: true,
          record: schedule,
        };

        let position = this.layout.findIndex(
          (el) => el.x == grid.x && el.y == grid.y
        );
        this.layout.splice(position, schedule.duration);

        this.layout.push(grid);
      });
    },
  },
  computed: {
    filtredLayout: function () {
      if (this.layout) {
        return this.layout
          .filter((el) => el.visible == true)
          .sort((a, b) => {
            return b.y - a.y;
          });
      }
      return [];
    },
  },
  watch: {
    dataGrid: function (value) {
      if (this.updating) return;
      this.initiateLayout();
      value.forEach((schedule) => {
        let index = this.layout.findIndex(
          (el) => el.text.split(" ")[0] == schedule.time
        );

        let grid = {
          h: schedule.duration,
          i: schedule._id,
          text: `${schedule.subject.name} ${
            schedule.room ? schedule.room.name : ""
          }  ${schedule.classeRoom.name}`,
          w: 2,
          x: 2 * schedule.day + 2,
          y: this.layout[index].y,
          s: false,
          visible: true,
          filled: true,
          record: schedule,
        };

        let position = this.layout.findIndex(
          (el) => el.x == grid.x && el.y == grid.y
        );
        this.layout.splice(position, schedule.duration);

        this.layout.push(grid);
      });
    },
    errorDrag(value) {
      if (value == true) {
        this.reload();
      }
    },
    updating(value) {
      if (value == true) {
        this.layout.forEach((el) => {
          if (el.notFilled) {
            el.visible = false;
          }
        });
      } else {
        this.reload();
      }
    },
  },
};
</script>

<style>
.container .vue-grid-item.vue-grid-placeholder {
  background: rgb(2, 208, 223);
}

.vue-grid-layout {
  -webkit-transition: none;
  transition: none;
}

.vue-grid-item:not(.vue-grid-placeholder).header {
  font-size: 18px;
  font-weight: bold;
  background-color: rgb(28, 76, 116);
  color: white;
  margin-top: 1px;
}

.vue-grid-item:not(.vue-grid-placeholder).filled {
  background-color: rgb(212, 212, 212);
  color: rgb(29, 29, 29);
  border-radius: 5px;
}

.vue-grid-item:not(.vue-grid-placeholder).notFilled {
  border-bottom: solid 1px rgb(201, 201, 201);
  color: white;
}

.vue-grid-item:not(.vue-grid-placeholder).notFilled:hover {
  background-color: rgb(212, 212, 212);
  cursor: pointer;
}

.vue-grid-item:not(.vue-grid-placeholder).filled:hover {
  cursor: pointer;
  opacity: 0.8;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  position: relative;
}

.vue-grid-item:not(.vue-grid-placeholder) .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px 0;

  /* Position the tooltip */
  position: absolute;

  width: 120px;
  top: 35px !important;
  left: 50%;
  margin-left: -60px;

  opacity: 0.7;
}

.vue-grid-item:not(.vue-grid-placeholder).filled .tooltiptext {
  top: 75% !important;
}

.vue-grid-item:not(.vue-grid-placeholder) .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.vue-grid-item:not(.vue-grid-placeholder):hover .tooltiptext {
  visibility: visible;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item.header .text {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item.filled .text {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item.filled .textCenter {
  top: 50%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: grab;
}

.vue-draggable-handle {
  position: absolute;
  font-size: 20px;
  top: 0;
  right: 0;
  padding: 0 8px 8px 0;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

.loading {
  opacity: 70%;
}
</style>
