<template>
  <a-card :hoverable="true" class="stat-card">
    <a-statistic :title="title" :value="text" >
        <template #prefix>
          <a-icon :type="icon" />
        </template>
    </a-statistic>
  </a-card>
</template>
<script>
export default {
    props: ['title','text','icon']
}
</script>
<style >

[data-kit-theme="default"] .stat-card .ant-card-body {
    padding: 10px !important;
     background: #eee;
     border-radius: 2px;
}
</style>